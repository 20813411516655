
const links = document.querySelectorAll('a')
const body = document.querySelector('body')
if (links) {
  links.forEach((link) => {
    link.onclick = (e) => {
      const linkTarget = link.href
      const anchorLink = linkTarget.includes('#')
      const emptyLink = link.getAttribute('href').trim().length
      const targetBlank = link.getAttribute('target')
      let newPage = false
      if (targetBlank === '_blank') {
        newPage = true
      }
      if ((!(anchorLink)) && (emptyLink > 0) && (!newPage)) {
        e.preventDefault()
        setTimeout(function () {
          if (body.classList.contains('exit-out')) {
            console.log('Navigating to => ' + linkTarget)
            window.location = linkTarget
          }
        }, 620)
        if (body.classList.contains('slide-out')) {
          body.classList.add('animated', 'fadeOutUp', 'faster', 'exit-out')
        } else {
          body.classList.add('animated', 'fadeOut', 'faster', 'exit-out')
        }
      }
    }
  })
}
